.description-icon-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;

  // themes
  &.theme-green {

    .icon-wrapper {
      background: $color-green;
    }

  }

  &.theme-orange {

    .icon-wrapper {
      background: $color-orange-dark;
    }

  }


  .description-icon {
    margin-bottom: 24px;

    @include breakpoint(small) {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    @include breakpoint(medium) {
      display: inline-block;
      width: 50%;
    }

    @include breakpoint(large up) {
      width: 33%;
    }

    .icon-wrapper {
      width: 48px;
      height: 48px;
      min-width: 48px;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      @include breakpoint(small) {
        margin-right: 16px;
      }

      //.icon-svg {
      //  color: $color-white;
      //  fill: $color-white;
      //  mask: $color-white;
      //  filter: $color-white;
      //  stroke: $color-white;
      //}

      .icon {
        color: $color-white;
        font-size: 26px;
      }
    }

    .desc-title, .description {

      @include breakpoint(large up) {
        max-width: 95%;
      }
    }

    .desc-title {
      font-size: 16px;
      line-height: 1.25;
      margin-bottom: 8px;
    }

    .description {
      margin-bottom: 0;
      padding-right: 24px;

      .btn {
        @include breakpoint(small only) {
          width: auto;
        }
      }
    }
  }

  &.vertical-layout {

    .description-icon {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      @include breakpoint(small) {
        //width: 100%;
      }

      @include breakpoint(medium) {
        //width: 50%;
      }

      @include breakpoint(large up) {
        //width: 33%;
        margin-bottom: 56px;
      }
    }
  }
}

.section-what-you-get {
  //margin-top: 0 !important;

  .section-header {
    margin-bottom: 16px;
  }

  .desc {
    margin-bottom: 32px;
  }

  .btn-wrapper {
    margin: 8px 0 36px;
  }
}


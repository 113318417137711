
@media screen and #{breakpoint(large)} and #{breakpoint(xlarge down)} {
  .large-with-paddings {
    padding: 0 4rem;
  }
}

@include breakpoint(medium down) {
  .small-12 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

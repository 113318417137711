.section-foryou {
  padding-top: 0 !important;

  .section-header {
    margin-bottom: 8px;
  }

  .description-icon-wrapper {
    margin: 16px 0 8px;

    @include breakpoint(small) {
      margin-top: 32px;
    }
  }

  .lazy-player {
    margin-bottom: 32px;
  }
}

.section-legals {

  .legals-header {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.25;
  }

  .legal {
    font-size: 12px;
    line-height: 1.33;

    &:first-of-type {
      margin-bottom: 12px;
    }
  }
}

.details-slider {
  position: relative;
  margin: 32px 0 32px;
  padding: 20px 0 32px;
  overflow-x: hidden;

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;

    @include breakpoint(small only) {
      width: 10%;
    }

    @include breakpoint(medium up) {
      width: 10%;
    }
  }

  &:before {
    left: 0;
    background: linear-gradient(90deg, rgba(255,255,255,1) 11%, rgba(255,255,255,0) 83%);
  }

  &:after {
    right: 0;
    background: linear-gradient(270deg, rgba(255,255,255,1) 11%, rgba(255,255,255,0) 83%);
  }

  .swiper-wrapper {
    //align-items: center;
  }

  .swiper-slide {
    height: auto;
    cursor: pointer;

    @include breakpoint(small only) {
      width: 70%;
    }

    @include breakpoint(medium up) {
      width: 35%;
    }

    @include breakpoint(large up) {
      width: 40%;
    }

    @include breakpoint(xlarge up) {
      width: 26%;
    }

    &.inactive {
      display: none;
    }
  }

  .detail-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 24px 3px;
    box-shadow: 0px 0px 4px rgba(65, 65, 65, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 2px 8px rgba(65, 65, 65, 0.1);
    border-radius: 3px;

    @include breakpoint(small only) {
      padding-bottom: 0;
    }

    .icon-wrapper {
      width: 48px;
      min-width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 26px;
      margin-bottom: 16px;
      background-color: $color-gray-bg;
      border-radius: 50%;

      .icon {
        color: $color-gray-light;
        font-size: 26px;
      }
    }

    .card-header {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 1.25;
    }

    .card-badge {
      width: 100%;
      //max-width: 160px;
      display: block;
      margin-bottom: 16px;
      padding: 8px 12px;
      background: $color-orange-light;
      border-radius: 50px;
      color: $color-orange;
      font-family: $nndagny-display-normal;
      text-transform: uppercase;
      text-align: center;
    }

    .card-desc {
      position: relative;
      margin-bottom: 16px;
      padding-top: 16px;

      &:before {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: $color-gray-bg;
      }
    }

    .card-bottom {
      border-top: 1px solid $color-gray-bg;
      padding-top: 6px;
    }

    .btn {

      @include breakpoint(small only) {
        width: auto;
      }
    }
  }
}

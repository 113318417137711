.badges {
  display: block;
  font-size: 10px;

  span {
    display: inline-block;
    color: $color-gray-light;
    font-family: $nndagny-display-normal;
    text-transform: uppercase;
  }
}
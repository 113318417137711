@import "product-sidebar/common/variables";
@import "product-sidebar/components/widgets";

html.modalOpened {
  overflow: hidden !important;

  body {
    overflow: hidden !important;
  }
}

#karta {

  // Foundation
  @import "product-sidebar/_foundation/foundation";

  @include foundation-flex-grid;
  @include foundation-flex-classes;
  @include foundation-visibility-classes;

  // Variables
  @import "product-sidebar/common/colors";

  // Icons

  // Common
  @import "product-sidebar/common/base";
  @import "product-sidebar/common/accessibility";
  @import "product-sidebar/common/typography";
  @import "product-sidebar/common/buttons";
  @import "product-sidebar/common/mixins";

  // Utilities
  @import "product-sidebar/utilities/utilities";
  @import "product-sidebar/utilities/general";

  // Swiper
  @import "../../../node_modules/swiper/swiper";

  //Layout
  @import "product-sidebar/layout/layout";

  // Modules
  @import "product-sidebar/modules/lazy-youtube";
  @import "product-sidebar/modules/accordions";
  @import "product-sidebar/modules/modal";

  // Components
  @import "product-sidebar/components/side-nav";
  @import "product-sidebar/components/description-icon";
  @import "product-sidebar/components/why-details";
  @import "product-sidebar/components/opinions-slider";
  @import "product-sidebar/components/details-slider";
  @import "product-sidebar/components/pagination";
  @import "product-sidebar/components/breadcrumbs";
  @import "product-sidebar/components/sickness-table";

  // Sections
  @import "product-sidebar/sections/section-hero";
  @import "product-sidebar/sections/section-what-you-get";
  @import "product-sidebar/sections/section-details";
  @import "product-sidebar/sections/section-exclusions";
  @import "product-sidebar/sections/section-why";
  @import "product-sidebar/sections/section-foryou";
  @import "product-sidebar/sections/section-faq";
  @import "product-sidebar/sections/section-docs";
  @import "product-sidebar/sections/section-legals";

  background-color: #F7F7F7;
  color: $color-gray;
  font-family: $nndagny-text-regular;
  font-size: 16px;
  line-height: 1.25;
  text-align: left;

  .color-orange,
  .text-orange {
    color: $color-orange !important;
  }

  .design-system {
    @import "design-system/common/colors";
    @import "design-system/common/mixins";
    @import "design-system/components-ds/callout";
    @import "design-system/components-ds/header";
    @import "design-system/components-ds/hero";
    @import "design-system/components-ds/button";
    @import "design-system/components-ds/badges";
    @import "design-system/components-ds/buttons-group";
    @import "design-system/components-ds/button-scroll";
    @import "design-system/components-ds/button-with-login";
    @import "design-system/components-ds/list-simple";
    @import "design-system/components-ds/price-box";
    @import "design-system/components-ds/text-simple";
  }
}
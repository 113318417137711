.breadcrumbs {
  margin-top: 0;
  padding: 32px 0 12px;

  .crumb {
    position: relative;
    display: inline-block;
    padding: 0 14px;
    font-size: 12px;
    font-family: $nndagny-text-regular;
    line-height: 1.33;

    .crumb-link {
      color: #BDBDBD;
    }

    &:after {
      display: none;
    }

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      top: 0;
      left: -10px;
      background: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/crumb-chevron-gray.svg") no-repeat;
      background-size: cover;
    }

    &:first-of-type {
      padding-left: 0;

      &:before {
        display: none;
      }
    }

    &:last-of-type {
      color: $color-orange;
      font-family: $nndagny-display-normal;

      &:before {
        background: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/crumb-chevron-orange.svg") no-repeat;
      }
    }

    &:not(:last-of-type) {

      &:hover {

        .crumb-link {
          color: $color-orange;
        }
      }
    }
  }

}

/* BASE
--------------------------------------------------------------------------
========================================================================*/

*, *::before, *::after {
 box-sizing: border-box;
}

/* Document
------------------------------------------------------------------------*/

img[data-lazy-src] {
  width: 100%;
}

picture {
  img {
    display: block;
    width: 100%;
  }
}

#karta {
  background-color: #F7F7F7;
}

.row {
  //max-width: 105rem;
  max-width: 93.5rem;
}

.columns {

  @media (min-width: 1365px) {
    padding-right: 2.040625rem;
    padding-left: 2.040625rem;
  }
}

body > img {
  display: block;
  overflow: hidden;
  width: 0;
  height: 0;
}

[hidden] {
  display: none !important;
}

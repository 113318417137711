/* TYPOGRAPHY
--------------------------------------------------------------------------
========================================================================*/

h1, h2, h3, h4, h5, h6 {
  color: $color-gray;
  font-family: $nndagny-display-normal;
}

p {
  //font-size: 16px;
  line-height: 1.25;
  font-family: $nndagny-text-regular;

  //@include breakpoint(medium down) {
  //  margin-bottom: 24px;
  //}
  //
  //@include breakpoint(large) {
  //  margin-bottom: 16px;
  //}

  a {
    color: $color-orange;
    font-size: 16px;
    font-family: $nndagny-display-normal;
    line-height: 1.25;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $color-orange;
      text-decoration: underline;
    }

    &:visited {
      color: $color-orange-dark !important;
    }
  }
}

small,
.small {
  font-size: 10px;
  line-height: 1.2;
}

b,
strong {
  font-family: $nndagny-text-regular;
  font-weight: 700;
}

ul {
  margin-left: 0;
  list-style-type: none;
}

li {
  font-family: $nndagny-text-regular;
  font-size: 16px;
}

.narrow {

  @include breakpoint(large up) {
    max-width: 558px;
  }
}

.bullets-list {
  margin-top: 8px;
  margin-bottom: 0;

  .bullet-list-item {
    position: relative;
    padding-left: 16px;
    margin-bottom: 8px;

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      top: 6px;
      left: 0;
      background-color: $color-orange-dark;
      border-radius: 8px;
    }
  }
}
